import Vue from 'vue'
import Vuex from 'vuex'

import { nft } from './nft.module';
import { matter } from './matter.module';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  getters: {
  },
  modules: {
  nft,
  matter
  }
})
