import Vue from 'vue';
import Router from 'vue-router';
import VueMeta from 'vue-meta';
import Home from '../views/Home.vue';
import Meta from '../views/Meta.vue';

Vue.use(Router);
Vue.use(VueMeta);

export const router = new Router({
  mode: 'hash',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/home',
      component: Home
    },
    {
      path: '/meta',
      name: 'meta',
      component: Meta
    }
  ]
});


export default router
