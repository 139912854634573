<template>
  <div class="main">

  <div class="home">

   
    <div class="home2 container">
     <div class="imgBckg">
     <b-row>
     <b-col md="6" sm="6" class="mt-3">

     </b-col>
     <b-col md="6" sm="6" class="mt-3">
     <b-img class="mb-3" src="/smartcity.png" fluid></b-img>
     </b-col>
     </b-row>
     </div>

     <div class="container overlap">
    <div class="content container">
    
     <b-row>
     <b-col lg="6" md="6" sm="8" xs="12">
     <div class="block1 container">
     <h1>ecosys wallet</h1>
  
     <div>Smart Decentralized Ecosystem in Industry 4.0 and Web3</div>
     <div>A model of community based on Smartcities</div>
    
    
     
    </div>
     </b-col>
     
     </b-row>
     
     </div>
     </div>
     </div>

     
  </div>
    
    
     <div class="heading">

     <b-row align-v="center">
     <b-col md="6" sm="6" >
     <b-img src="/leaf.png" fluid></b-img>
     </b-col>
     <b-col md="6" sm="6" >

     </b-col>
     </b-row>

    </div>

    

    
     
     <div class="blockintro">

    <div class="container text-dark">

   
    
   

      <b-row class="text-center" align-v="center">
     <b-col md="6" sm="6">
     <div class="block1">
     
  
     <h1>ecosys</h1>
      <div>Smart network of people and interconnected assets bridging our tangible world and virtuality.</div>
   
    
     
    </div>
     </b-col>

     <b-col md="6" sm="6">
     
     <b-img class="mb-3" src="/wt.png" fluid></b-img>

     </b-col>
     
     </b-row>
 
     </div>
     </div>

  
       

     <div class="block6">
    <div class="container text-dark">

      
     <b-row align-v="center" class="mt-3">
     <b-col md="6" sm="6" >
     </b-col>
     <b-col md="6" sm="6" >
      <h1>ecosphere</h1>
      </b-col>

      </b-row>

      <b-row align-v="center" class="mt-3">
     <b-col md="6" sm="6" >
    
     <b-img src="/matter.png" fluid></b-img>
   
     </b-col>
     <b-col md="6" sm="6" >

      <div class="mt-3">An Open source community of Artist, Engineers and Cryptotraders that harness the power of Decentralization to improve lifestyle, safety, security and ecology through the concept of Ecosystems;</div> 
      <div class="mt-3">complexe structures that can be monitored, rearranged and controled in order to interpet patterns or create environments.</div>



     </b-col>

     </b-row>   

     </div>
     </div>

     

     <div class="block7">
    <div class="container text-dark">

     <div class="spacer"></div>
     
     <b-row align-v="center" class="mt-3">
     <b-col md="6" sm="6" >

     <h1>ecosystem</h1>
      
     </b-col>
     <b-col md="6" sm="6" >

     
      </b-col>
      </b-row>
      <b-row align-v="center">
     <b-col md="6" sm="6" >


     <div class="mt-3">Decentralized Fullstack Websites and applications development.</div>

     <div class="mt-3">Continuous integration, Decentralized hosting, Automation</div>

     <div class="mt-3">Decentralized small scale wind generator production</div>

     
     </b-col>
     <b-col md="6" sm="6" >
     
      <b-img src="/ipfs.png" fluid></b-img>
      
     </b-col>

     </b-row>



     </div>
     </div>

     <div class="block65">
    <div class="container text-dark">

      <div class="text-center mb-3">
    
      <h1>Social ecology</h1>
      
      </div>
      <b-row align-v="center" class="mt-3">
     <b-col md="6" sm="6" >
    
     <b-img src="/ladybug.png" fluid></b-img>
   
     </b-col>
     <b-col md="6" sm="6" >

      <div class="mt-3">We belive in social ecology and apply its concepts to emerging technologies such as the Blockchain and Web3.</div> 
      <div class="mt-3">We build web applications hosted on the Decentralized web to prevent censorship and downtime around the world.</div>
      <div class="mt-3">We develop decentralized small scale wind generators that adapt to communities and redefine private and public right in terms of energy while helping the environnment.</div>



     </b-col>

     </b-row>   

     </div>
     </div>

     
     <div class="block12">
    <div class="container text-dark">

      <b-row align-v="center" class="mt-3 mb-3">
     <b-col md="6" sm="6" >
     </b-col>
     <b-col md="6" sm="6" >
      <h1>Technology</h1>
      </b-col>

      </b-row>
      <b-row align-v="center">
     
     <b-col md="6" sm="6" >
     <h3>Web</h3>
      <div class="mb-3">Fullstack websites and web applications that adapt to communities integrating APIs, Databases, IoT and Blockchain. Hosting services on the decentralized web to create censorship resistant platforms with 0 downtime.</div>
    </b-col>

    <b-col md="6" sm="6" >
     

     <b-img src="/inge.png" height="120" center></b-img>
    
     </b-col>
   
      </b-row>
    <b-row align-v="center" class="mt-5">
    
     <b-col md="6" sm="6" >
     <h3>Shou</h3>
     
     <div>Small scale vertical axis wind generator</div>
      <div class="mt-3">Conjuging the power of two decentralized technologies, our wind generators mint the ECO token with extra power to get earnings and become an actor in the green energy market.</div>


     </b-col>

     <b-col md="6" sm="6" class="mt-3">
     

     <b-img src="/shou.png" fluid></b-img>
    
     </b-col>

     </b-row>

     </div>
     </div>



  
    
     <div class="block2">

     <div class="container text-dark">


      <b-row>
        <b-col md="6" sm="12">
        
        <div class="txtdisp text-center">

        <h1>ECO | Token</h1>
         
          </div>
        </b-col>
        <b-col md="6" sm="12">
          
        <div class="mt-3">ECO Token is a currency which will depend on the variations of the green energy market. Sell your extra renewable electricity by minting with our small scale wind generators and get earnings.</div>

        <div class="text-center">
          <b-button class="mt-3" variant="nature1" size="md" to="/meta">
            Get some ECO
            </b-button>
        </div>
       
        </b-col>
      </b-row>

     </div>
     </div>
     


     <div class="block22">

     <div class="container text-dark">

      <b-row>
        <b-col md="6" sm="12">
        <b-card bg-variant="light">
        <h4 class="head">ECO Token is in presale. Get some tokens to get involved early into Ecosys decentralized market. Ecosys is combining the power of decentralized small scale renewable energies, Blockchain and Open Source communities to shape the future.</h4>
        </b-card>
        </b-col>
        <b-col md="6" sm="12">
          <div class="txtdisp text-center">
          ECO
          </div>
        </b-col>
      </b-row>

     </div>
     </div>

<hr>
     <div class="block3">
    <div class="container text-center text-dark">
      <b-card bg-variant="light">
    
     <b-img class="mx-3" src="/ethereumlogo.png" height="60"></b-img>
     <b-img class="mx-3" src="/metamaskfox.png" height="60"></b-img>

    <div class="title mt-3"><a href="https://metamask.io/download/">Installing Metamask</a></div>
    <div>Adding ECO Token</div>
    
 
    </b-card>
    </div>
     </div>


    

    

  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      content: ''
    };
  },
  metaInfo() {
    return { 
      title: "wallet.ecosys",
      meta: [
        { vmid: 'description', name: 'description', content:  'wallet.ecosys' },
        { vmid: 'og:title', property: 'og:title', content: "wallet.ecosys" },
        { vmid: 'og:site_name', property: 'og:site_name', content: 'wallet.ecosys' },
        { vmid: 'og:type', property: 'og:type', content: 'website' },    
        { name: 'robots', content: 'index,follow' } 
      ]
    }
  },
  mounted() {
    
  },
  computed: {
    
  }
};
</script>
<style scoped>
.spacer {
  height: 60px;
}

.add {
  font-size: 8px;
}
.poly {
  color: #8247e5;
}
.text2 {
  color: #d3d3d3;
}
.meta {
  color: #15e0b9;
}
.fuchsia {
  color: #ea3c4c;
}
.ether {
  color: #ffffff;
}
.heading {
  
  background: linear-gradient(to bottom, #9df4bf, #f2e9ba);
  padding-top: 30px;
  
}
.block {
  position: relative;
  background: linear-gradient(to bottom, #1DE9B6, #f2e9ba);
  display: flex;

}
.block1 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.block2 {
  background-color: #f8f9fa;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: justify;
}
.block22 {
  background-color: #f8f9fa;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: justify;
}
.block3 {
  background-color: #f8f9fa;
  padding-top: 60px;
  display: block;
}
.block4 {
  background: linear-gradient(to bottom, #f2e9ba, #f8f9fa);
  padding-top: 60px;
  padding-bottom: 60px;
  display: block;
}

.blockintro {
  background-color: #f8f9fa;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.block5 {
  position: relative;
  background-color: #69F0AE;

}
.block6 {
  background: linear-gradient(to bottom, #f2e9ba, #B9F6CA);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: justify;


}
.block65 {
  background: linear-gradient(to bottom, #B9F6CA, #f2e9ba);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: justify;


}
.block7 {
  background-color: #f8f9fa;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.block8 {
  position: relative;
  bottom: 0;
  display: flex;
  width: 100%;
}

.block9 {
  position: relative;

  background-color: #f8f9fa;
  padding-top: 10px;
  padding-bottom: 10px;
}
.block10 {
  position: relative;
  background-color: #b6edd3;
  padding: 10px;

}
.block11 {
  position: relative;
  background-color: #69F0AE;
  padding: 10px;

}
.block111 {
  position: relative;
  background-color: #00E676;
  padding: 10px;

}
.block112 {
  position: relative;
  background-color: #b6edd3;
  padding: 10px;

}

.block12 {
  background-color: #f8f9fa;
  text-align: justify;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

}
.block13 {
  position: relative;
  background-color: #64FFDA;
  padding-top: 40px;
  padding-bottom: 40px;

}

.block14 {
  position: relative;
  background-color: #64FFDA;
  height: 35px;

}


.box1 {
  padding: 10px;
  position: relative;
  background-color: #64FFDA;
  border-radius: 10px;

}
.box2 {
  padding: 10px;
  position: relative;
  background-color: #f2e9ba;
  border-radius: 10px;

}
.home {
  position: relative;
  top: 0;
  height: 50vh;
  display: block;
  padding-bottom: 20px;
  background: linear-gradient(to bottom, #00BFA5, #9df4bf);
  
}
.home1 {
  position: relative;
  top: 0;
  height: 50vh;
  display: flex;
  padding-bottom: 20px;

  
}
.home2 {
  position: relative;
  top: 130px;
  right: 10px;
  height: 50vh;
  display: flex;
 
  justify-content: center;

  
}
.home3 {
  position: relative;

  
}
.content {
  display: inline-block;
  align-self: flex-end;

}
.content2 {
  display: inline-block;
  align-self: flex-end;
  height: 20px;
  width: 100%;
  background: linear-gradient(to bottom, #A1887F, #A1887F);
}


.imgBckg {
  display: inline-block;
  align-self: flex-end;
  justify-content: center;
}

.overlap{
    position: absolute;
    top: 0px;
    left: 0px;
    z-index:10;
}
.content1 {
  display: inline-block;
  align-self: flex-end;
}
.txtdisp {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 38px;
}
.vcenter {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ln {
  border: 1px solid #7c7c7c;
}
.main {
 overflow: hidden;
}
</style>